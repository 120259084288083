
<template>
  <div id="page-user-rdit">
      <user-email-verification v-if="!activeUser.is_verified" />
      <div class="vx-row">
        <div class="vx-col lg:w-2/3 w-full">
          <is-loading v-if="isLoading" />
          <vx-card v-else >
            <div slot="no-body" class="tabs-container px-6 pt-6">
                <vs-tabs :value="tabs[activeTab]" class="tab-action-btn-fill-conatiner">
                  <vs-tab :label="!isSmallerScreen ? 'Profile' : 'Acc'" icon-pack="feather" icon="icon-user">
                    <div class="tab-text">
                      <user-edit-tab-account class="mt-4" />
                    </div>
                  </vs-tab>
                  <vs-tab :label="!isSmallerScreen ? 'Settings' : 'Sett'" icon-pack="feather" icon="icon-settings">
                    <div class="tab-text">
                      <user-edit-tab-settings class="mt-4" />
                    </div>
                  </vs-tab>
                  <vs-tab :label="!isSmallerScreen ? 'Password' : 'Pass'" icon-pack="feather" icon="icon-lock">
                    <div class="tab-text">
                      <user-edit-tab-password class="mt-4" />
                    </div>
                  </vs-tab>
                </vs-tabs>
            </div>
          </vx-card>
        </div>
      </div>
  </div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import UserEditTabAccount     from "./UserEditTabAccount.vue"
import UserEmailVerification    from "@/components/UserEmailVerification.vue"
import UserEditTabSettings from "./UserEditTabSettings.vue"
import UserEditTabPassword from "./UserEditTabPassword.vue"

export default {
  components: {
    UserEmailVerification,
    UserEditTabAccount,
    UserEditTabSettings,
    UserEditTabPassword,
    IsLoading,
  },
  data() {
    return {
      isLoading: true,
      tabs:{
        profile: 0,
        settings: 1,
        password: 2,
      },
    }
  },
  computed: {
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768
    },
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    activeTab(){
      return this.$route.query.tab || 'profile'
    },
  },
  created() {
    this.$store.dispatch("fetchActiveUser")
      .then((response) => {
        this.isLoading = false
        if(!response.data.success){
          this.$router.push({path: '/error-404'})
        }
    })
    if(!this.$store.state.countries.length){
        this.$store.dispatch('fetchCountries')
    }
  }
}

</script>
